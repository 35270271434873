@import "./asset/scss/themes-vars.module";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

@font-face {
  font-family: "PoppinsVariableFont";
  src: local("poppinsVariableFont"),
    url("./asset/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
.MuiBox-root:focus {
  outline: none;
  border: 1px solid transparent;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.customInput_padding > .MuiInputBase-root {
  padding: 5px 10px;
}

.customInput_padding > .MuiInputBase-root::before {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInputBase-root::after {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::before {
  border-bottom: 2px solid $errorMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::after {
  border-bottom: 2px solid $errorMain !important;
}

.swal2-container {
  z-index: 99999;
}

.swal2-confirm,
.swal2-cancel {
  outline: none !important;
  box-shadow: none !important;
}
.slick-prev:before, .slick-next:before {
  color: grey !important;
}


.profile-step {
  .MuiStepLabel-root .Mui-completed {
    color: green !important;
  }

  .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
    color: grey.500 !important;
  }

  .MuiStepLabel-root .Mui-active {
    color: #547dbf;
  }

  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    color: white !important;
  }

  .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
    fill: white !important;
  }

  .MuiStepLabel-labelContainer > span {
    font-size: 1.2rem !important;
    font-weight: 550 !important;
    padding: 2rem 0 !important;
  }

  .MuiStepLabel-iconContainer .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
}

/* legacy search button design */
.legacy-search {
  width: 12%;
  display: flex;
}

.legacy-search-div {
  cursor: pointer;
  width: 100%;
  height: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  margin-top: 2.6rem;
  border: 1px solid #ccc;
  background-color: #dcdbdf;
}

/* Styles for screens with a width between 481 and 960 pixels */
@media screen and (min-width: 360px) and (max-width: 960px) {
  .profile-step {
    .MuiStepLabel-label {
      display: flex !important;
    }

    .MuiStepLabel-root .Mui-completed {
      color: green !important;
    }

    .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
      color: grey.500 !important;
    }

    .MuiStepLabel-root .Mui-active {
      color: #547dbf;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: white !important;
    }

    .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
      fill: white !important;
    }

    .MuiStepLabel-labelContainer > span {
      font-size: 0.75rem !important;
      font-weight: 500 !important;
      padding: 0.2rem 0 !important;
      display: flex !important;
      justify-content: center !important;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: #547dbf !important;
    }

    .MuiStepLabel-iconContainer .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }
  }
  .css-15pdb31-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
  }
}

.MuiPickersLayout-root {
  .MuiButton-root {
    background-color:#0Fadd5 !important;
  }
}

.Textarea_design {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1.2;
  font-family: Poppins;
  padding: 12px;
  resize: none;
  max-height: 124px !important;
  overflow-y: auto !important;
  background: #4091d60d !important;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  .Textarea_design :focus-visible{
    border: none !important;
    outline: none !important;
  }
}

.error-boundary {
  display: flex;
  text-align: center;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.image-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  width: '100%';
  background-color:#ffffff;
  margin-top: .5rem;
  row-gap: .2rem;
  column-gap: .2rem;
}

iframe {
  width: 100%;
  height: 80vh;
}